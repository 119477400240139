/* eslint-disable no-undef */
import React, { useState } from "react"
import data from "@components/pageTicker/data"
import Footer from "@components/sharedComponents/headerAndFooter/footer/footer"
import FooterMobile from "@components/sharedComponents/headerAndFooter/footer/footerMobile"
import Header from "@components/sharedComponents/headerAndFooter/header/header"
import Start from "@components/pageTicker/intro/intro"
import Customers from "@components/sharedComponents/customersLogos/customersLogos"
import BannerCTA from "@components/sharedComponents/bannerCTA/bannerCTA"
import BannerContact from "@components/sharedComponents/bannerContact/bannerContact"
import ArticleBlog from "@components/sharedComponents/articleBlog/articleBlog"
import AccordionLeft from "@components/sharedComponents/accordionLeft/accordionLeft"
import AccordionRight from "@components/sharedComponents/accordionRight/accordionRight"
import Testimonies from "@components/sharedComponents/testimonies/testimonies"
import BannerCookies from "@components/sharedComponents/bannerCookies/bannerCookies"
import BannerLogin from "@components/pageTicker/bannerLogin/bannerLogin"
import Integrations from "@components/pageTicker/integrations/integrations"
import Experience from "@components/pageTicker/experience/experience"
import TitleUnderlineLeft from "@components/pageTicker/titleUnderline/titleUnderlineAccordionLeft"
import TitleUnderlineRight from "@components/pageTicker/titleUnderline/titleUnderlineAccordionRight"
import TitleUnderlineTestimony from "@components/pageTicker/titleUnderline/titleUnderlineTestimony"
import finanzas from "@components/sharedComponents/testimonies/images/iconos/finanzas.svg"
import logistica from "@components/sharedComponents/testimonies/images/iconos/logistica.svg"
import educacion from "@components/sharedComponents/testimonies/images/iconos/educacion.svg"
import ecommerce from "@components/sharedComponents/testimonies/images/iconos/ecommerce.svg"
import concesionarias from "@components/sharedComponents/testimonies/images/iconos/concesionarias.svg"
import VideoDesktop from "@components/pageTicker/images/VideoTicker.mp4"


const TemplatePageTicker = ({ location }) => {
  //Video youtube
  const [videoTicker, setVideoTicker] = useState(false)
  const openVideoTicker = value => {
    setVideoTicker(value)
  }


  //velocidad de entrada de textos y animaciones para desktop y mobile
  const windowsWidth = true

  const titleAccordionLeft = <p><TitleUnderlineLeft underline="Automatiza" /> tus conversaciones y dale super poderes a tus chats</p>
  const titleAccordionRight = <p> Combina acciones por canales y  <TitleUnderlineRight underline="personaliza" /> las comunicaciones con tus clientes</p>
  const titleTestimony = <p>Con , empresas de <TitleUnderlineTestimony underline="todas las industrias" /> ya mejoran sus principales métricas de satisfacción, marketing y ventas</p>


  const iconsSilder = i => {
    switch (i) {
      case 0:
        return finanzas
      case 1:
        return ecommerce
      case 2:
        return concesionarias
      case 3:
        return logistica
      case 4:
        return educacion
      default:
        return ""
    }
  }
  const titleSilder = i => {
    switch (i) {
      case 0:
        return "Finanzas"
      case 1:
        return "Ecommerce "
      case 2:
        return "Concesionarias "
      case 3:
        return "Logística"
      case 4:
        return "Educación"
      default:
        return "Conoce otros casos de éxito"
    }
  }


  return (
    <div className="fnd">
      <section>
        <BannerCookies />
        <div className="banner-invisible-ebook" />
        <div>
          <Header
            path="/productos/"
            location={location}
            bannerCTA={true}
            windowsWidth={windowsWidth}
          />
        </div>
        <BannerLogin />
        {videoTicker ? (
          <>
            <button
              className="button-video-youtube-beex"
              onClick={() => {
                openVideoTicker(false)
              }}
            >
              X
            </button>
            <div className="container-video-youtube-beex">
              <div className="container-video-youtube-beex-background"></div>
              <div className="container-video-youtube-beex-iframe">
                <video className="container-video-youtube-beex-iframe-video" controls autoPlay={true}>
                  <source src={VideoDesktop} type="video/mp4" />
                </video>
              </div>
            </div>
          </>
        ) : (
          ""
        )}


        <div className="container">
          <Start data={data.start} location={location} openVideo={setVideoTicker} />
          <Customers data1={data.customersDesktop} data2={data.customersTablet} title="Estas empresas ya mejoran la comunicación con sus clientes con Beex" />
          <AccordionLeft data={data.accordionLeft} title={titleAccordionLeft} description={"Beex permite a los equipos de servicio al cliente, marketing y ventas automatizar sus procesos con tecnología omnicanal potenciada con IA, logrando mejorar sus métricas más importantes."} />
          <Testimonies data={data.testimonies} location={location} title={titleTestimony} iconsSilder={iconsSilder} titleSilder={titleSilder} />
          <BannerCTA
            title="Empieza a mejorar la comunicación con tus clientes, ahora mismo"
            text="Accede a una demo total y comprueba como te ayudaría nuestra tecnología omnicanal."
            link="/hablemos/"
            textButton="Comunicarme con ventas"
          />
          <AccordionRight data={data.accordionRight} list={true}
            title={titleAccordionRight}
            description={" Conoce algunos casos de uso donde Beex usa diferentes canales y automatiza acciones para mejorar la experiencia de tus clientes."}
          />
          <Experience data={data.modules} location={location} />
          <BannerCTA
            title="¡Mejora tu comunicación con Beex!"
            text="Accede a una Demo en tiempo real de la herramienta sin costo alguno, agenda una reunión con uno de nuestros expertos"
            link="/hablemos/"
            textButton="Comunicarme con ventas"
          />
          <Integrations location={location} />
          <ArticleBlog data={data.articles} home={true} />
          <BannerContact data={data.contacts} location={location} />
          <Footer data={data.footer} location={location} />
          <FooterMobile data={data.footer} location={location} />
        </div>
      </section>
    </div>
  )
}

export default TemplatePageTicker
